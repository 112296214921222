import { Canvg } from 'canvg';
import kebabCase from 'lodash/kebabCase';

import { clamp } from '../utils/number';

function getSanitizedSVGContent() {
  const sankeySVG = document.getElementById('sankey_svg');
  if (!sankeySVG) {
    return;
  }
  return (
    sankeySVG.outerHTML
      // Remove style attribute applied in SankeySVG component to avoid Canvg rendering issues.
      // The style is used to make the SVG container responsive in the web browser but is not needed for the PNG generation.
      .replace(/ style\=\".*\">/g, '>')
      // Replace all &nbsp; entities from the SVG content to avoid rendering issues (see https://github.com/sankeyart/sankeyart/issues/1160)
      .replaceAll('&nbsp;', ' ')
      // Add some line breaks to highlight where [g]roups start/end
      // and where each path/text/rect begins:
      .replace(/><(g|\/g|path|text|rect)/g, '>\r\n<$1')
  );
}

// Build a PNG file in the background
// TODO: Read svgContent directly from the rendered static SVG element or svg_representation field for editable Sankeys.
function renderPNG(filePrefix: string) {
  const chartEl = document.getElementById('chart');
  const canvasEl = document.getElementById('png_preview') as
    | HTMLCanvasElement
    | undefined;
  const canvasContext = canvasEl?.getContext('2d');
  const sankeySVG = document.getElementById('sankey_svg');
  const svgContent = getSanitizedSVGContent();
  const downloadScalePNG = document.getElementById('download_scale_png') as
    | HTMLInputElement
    | undefined;

  if (
    !chartEl ||
    !canvasEl ||
    !canvasContext ||
    !sankeySVG ||
    !svgContent ||
    !downloadScalePNG
  ) {
    return;
  }

  const orig = {
      w: Number(sankeySVG.getAttribute('width')),
      h: Number(sankeySVG.getAttribute('height')),
    },
    // What scale does the user want (1,2,4,6)?:
    scaleFactor = clamp(downloadScalePNG.value, 1, 6),
    scaled = { w: orig.w * scaleFactor, h: orig.h * scaleFactor },
    // Canvg 3 needs interesting offsets added when scaling up:
    offset = {
      x: (scaled.w - orig.w) / (2 * scaleFactor),
      y: (scaled.h - orig.h) / (2 * scaleFactor),
    };

  // Set the canvas element to the final height/width the user wants:
  canvasEl.width = scaled.w;
  canvasEl.height = scaled.h;

  // Give Canvg what it needs to produce a rendered image:
  const canvgObj = Canvg.fromString(canvasContext, svgContent, {
    ignoreMouse: true,
    ignoreAnimation: true,
    ignoreDimensions: true, // DON'T make the canvas size match the SVG
    scaleWidth: scaled.w,
    scaleHeight: scaled.h,
    offsetX: offset.x,
    offsetY: offset.y,
  });
  canvgObj.render();

  // Turn canvg's output into a PNG and attach it to the download link
  const element = document.getElementById('download_link_inchart_png');
  element?.setAttribute('href', canvasEl.toDataURL('image/png'));
  element?.setAttribute(
    'download',
    `${filePrefix}_${scaled.w}x${scaled.h}.png`
  );
}

// Take the current state of 'sankey_svg' and links it to the download button
// TODO: Consider reading directly from the rendered static SVG element or svg_representation field for editable Sankeys.
function generateSVG(filePrefix: string) {
  const svgContent = getSanitizedSVGContent();
  if (!svgContent) {
    return;
  }
  // Turn canvg's output into a SVG
  const svgBlob = new Blob([svgContent], {
    type: 'image/svg+xml;charset=utf-8',
  });
  const svgUrl = URL.createObjectURL(svgBlob);

  // Attach the SVG to the download link
  const element = document.getElementById('download_link_inchart_svg');
  element?.setAttribute('href', svgUrl);
  element?.setAttribute('download', `${filePrefix}.svg`);
}

// Kick off a re-render of the static image and the user-copyable SVG code in the background.
// This is called in Django once the user opens the SVG / PNG export menu.
window.renderExportableOutputs = () => {
  // Clear out the old image links.
  document
    .getElementById('download_link_inchart_png')
    ?.setAttribute('href', '#');
  document
    .getElementById('download_link_inchart_svg')
    ?.setAttribute('href', '#');

  const diagramTitle =
    document.getElementById('sankey_svg')?.getAttribute('title') ?? 'diagram';
  const formattedTimestamp = (
    new Date().toISOString().replace(/T.+$/, '') +
    new Date().toTimeString().replace(/ .+$/, '')
  ).replace(/[:-]/g, ''); // Generate yyyymmddhhmmss string
  const filePrefix = `${kebabCase(diagramTitle)}-${formattedTimestamp}`;

  // Fire off asynchronous events for generating the export output so we can give control back asap.
  setTimeout(() => renderPNG(filePrefix), 0);
  setTimeout(() => generateSVG(filePrefix), 0);
};
