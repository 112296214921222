import parseDecimalNumber from 'parse-decimal-number';

/*
 * Returns true iff the provided value is a number.
 */
export function isNumeric(n: any): boolean {
  return !Number.isNaN(n - parseFloat(n));
}

/*
 * Formats a number to a string with maximum of 10 decimal places, removing trailing zeros.
 * Returns empty string if the provided value is not a number.
 */
export function toDecimalString(n: any): string {
  return isNumeric(n)
    ? Number(n)
        .toFixed(10)
        .replace(/\.?0+$/, '')
    : '';
}

/*
 * Parses a string to a number, supporting both US and EU formatting (US: 1,234.56; EU: 1.234,56).
 * In case of ambiguity (e.g. 1,234), US formatting is favored. Returns NaN if not a valid number.
 */
export function safeParseFloat(n: string | number | null | undefined): number {
  if (typeof n === 'number') {
    return n;
  } else if (n === null || n === undefined) {
    return NaN;
  }
  let result = parseDecimalNumber(n, { thousands: ',', decimal: '.' });
  if (!isNumeric(result)) {
    result = parseDecimalNumber(n, { thousands: '.', decimal: ',' });
  }
  return result;
}

/*
 *Ensure a numeric value n is between min and max.
 * Default to min if not numeric.
 */
export function clamp(n: any, min: number, max: number) {
  return isNumeric(n) ? Math.min(Math.max(n, min), max) : min;
}
